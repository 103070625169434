import React from 'react'
import styles from './style.less'
const InsHeader = (props) => {
  // 跳过
  const skipStepFn = () => {
    props.skipStep && props.skipStep()
  }
  return <>
    <div className={styles.insWrap}>
        <p>选择您的<span>{props.title}</span></p>
        <p>{props.tips}</p>
        <div className={styles.turn} onClick={()=>skipStepFn()}>跳过</div>
      </div>
  </>
}

export default InsHeader
