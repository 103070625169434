/*
 * @Author: 顾喜斌 xibin_gu@163.com
 * @Date: 2022-08-31 17:49:13
 * @LastEditors: 顾喜斌 xibin_gu@163.com
 * @LastEditTime: 2022-09-01 11:06:20
 * @FilePath: /sscp_h5/src/pages/huayang/selectIns/services.js
 */
import request from '@/utils/request';

// 获取一级标签
export async function selectByCode(params){
  return request('baseApi/index/selectByCode',{method:'get', params})
}

// 获取用户信息
export async function getByBfUserId(params) {
  return request('bfUser/encrypt/getByBfUserId',{method:'get', params,headers:{userId:params.userId}})
}

// 上传用户信息
export async function updateUserInfo(params) {
  return request('bfUser/updateUserExtendInfo',{method:'POST',data: params, headers:{userId:params.userId}})
}
