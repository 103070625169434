import {
  WX_APP_ID,
} from "./constants";

//判断环境
export function isEnv(result) {
  var ua = window.navigator.userAgent.toLowerCase();
  const isWeixin = ua.indexOf('micromessenger') !== -1
  if (isWeixin) {
    const localWxAppId = window.localStorage.getItem('wxAppId')
    if (WX_APP_ID != localWxAppId) {
      window.localStorage.removeItem('openId')
      window.localStorage.setItem('wxAppId', WX_APP_ID)
    }
    window.wx.miniProgram.getEnv(function (res) {
      const channel = res.miniprogram ? "miniapp" : "wechat"
      window.localStorage.setItem('channel', channel)
      result()
    })
  } else {
    // eslint-disable-next-line
    if (typeof JSBridge !== "undefined") {
      // eslint-disable-next-line
      const deviceInfo = JSBridge.getData('deviceInfo');
      const channel = JSON.parse(deviceInfo).app === "sunlandapp" ? "App" : "liteApp"
      window.localStorage.setItem('channel', channel)
      result()
    } else {
      result()
    }
  }
}
//app里面jsBridge里面的userId
export function getJSBriId() {
  if (typeof JSBridge !== "undefined") {
    // eslint-disable-next-line
    if (JSON.parse(JSBridge.getData()).userInfo && JSON.parse(JSBridge.getData()).userInfo.userId) {
      // eslint-disable-next-line
      window.localStorage.setItem('userId', JSON.parse(JSBridge.getData()).userInfo.userId)
      // eslint-disable-next-line
      return JSON.parse(JSBridge.getData()).userInfo.userId
    }
  }
}
