import request from '@/utils/request';
// 获取直播间信息
export const getLiveInfoForH5 = async (data) => {
 return request('jx/smallapi/api/liveRoom/getLiveInfoForH5', {
    method: 'POST',
    data
  })
}

// 获取直播预告列表
export const getPreviewList = async (data) => {
  return request('jx/smallapi/small/site/preview/list', {
     method: 'POST',
     data
   })
}
// 获取往期直播列表
export const getPastCourseList = async (data) => {
  return request('jx/smallapi/small/site/past/courseList', {
     method: 'POST',
     data
   })
}
// 获取小站详情
export const getSiteInfo = async (data) => {
  return request('jx/smallapi/small/site/query', {
     method: 'POST',
     data
   })
}
// 获取用户详情
export const getUserInfo = async (params) => {
  return request('jx/smallapi/api/index/getUserById', {
     method: 'GET',
     params
   })
}


/**
 * 埋点新接口
 * @param {Object} data
 * @param {string} data.actionId
 * @param {string} data.actionKey
 * @param {string} data.pageKey
 * @return {*}
 */
export function newHandleDataTrace(data) {
  const source = localStorage.getItem('source')
  const brandId = localStorage.getItem('brandId')
  data.channelCode= `${source}_${brandId}`
  data.userId = '-1'
  return request('/traceUrl/actionInfo/add', { method: 'POST', data})
}
