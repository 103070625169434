import { createSignature, wxSaveShareLog } from '@/services/api'
const wx = window.wx
const isFeiBian = window.location.href.indexOf('feibians.com') > -1
//分享签名
export function wxConfig(params, jsApiArr = []) {
  createSignature(params).then((res) => {
    console.log(res, 'signature');
    if (res && res.code === 20000) {
      const signParams = res.data;
      wx.config({
        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
        appId: signParams.appId, // 必填，公众号的唯一标识
        timestamp: signParams.timestamp, // 必填，生成签名的时间戳
        nonceStr: signParams.nonceStr, // 必填，生成签名的随机串
        signature: signParams.signature,// 必填，签名
        jsApiList: ['updateAppMessageShareData', 'updateTimelineShareData'].concat(jsApiArr)//旧的接口，即将废弃  // 必填，需要使用的JS接口列表
      });
    }
  })
}
export function wxConfigWithOpenTag(params, jsApiArr = []) {
  createSignature(params).then((res) => {
    if (res && res.code === 20000) {
      const signParams = res.data;
      wx.config({
        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
        appId: signParams.appId, // 必填，公众号的唯一标识
        timestamp: signParams.timestamp, // 必填，生成签名的时间戳
        nonceStr: signParams.nonceStr, // 必填，生成签名的随机串
        signature: signParams.signature,// 必填，签名
        openTagList: ['wx-open-launch-app'],
        jsApiList: ['updateAppMessageShareData', 'updateTimelineShareData'].concat(jsApiArr)//旧的接口，即将废弃  // 必填，需要使用的JS接口列表
      });
    }
  })
}

//记录分享日志
export function saveShareLog(shareType, shareStatus, channel) {
  let params = {
    shareUserId: window.localStorage.getItem("userId") || "",
    shareOpenId: window.localStorage.getItem("openId") || '',
    source: window.localStorage.getItem("source") ? window.localStorage.getItem("source") : "",
    componentKey: window.localStorage.getItem("componentKey") ? window.localStorage.getItem("componentKey") : "studyReport",
    channel: channel ? channel : "h5",
    shareType: shareType,
    shareStatus: shareStatus
  }
  wxSaveShareLog(params)
}
//点击分享根据环境判断分享的内容及方式
export function envAndShare(channel, title, desc, pageUrl, imgUrl,miniImgUrl, componentKey) {
  if (channel === "miniapp") {
    saveShareLog("miniapp_share", 1, "miniapp")
    let data = {
      title: title,
      desc: desc,
      path: encodeURIComponent(pageUrl),
      imgUrl: miniImgUrl?miniImgUrl:imgUrl,
      isOpenUrl: true,
      componentKey: componentKey
    }
    miniAppShare(data)
  } else if (channel === "wechat") {
    let shareParams = {
      title: title, // 分享标题
      desc: desc, // 分享描述
      link: pageUrl, // 分享链接
      imgUrl: imgUrl,
      type: 'link', // 分享类型,music、video或link，不填默认为link
      success: function () {
        saveShareLog("friend_share", 1, "wechat")
      },
      cancel: function () {
        saveShareLog("friend_share", 0, "wechat")
      }
    }
    let shareFriendParams = {
      title: desc, // 分享标题
      link: pageUrl, // 分享链接
      imgUrl: imgUrl,
    }
    regShare(shareParams, shareFriendParams, componentKey)
  } else if (channel === "App" || channel === "liteApp") {
    const shareChannel = (channel === "App") ? "App" : "liteApp"
    saveShareLog("app_share", 1, shareChannel)
    let appShareParams = {
      title: title,
      content: desc,
      url: pageUrl,
      imgUrl: imgUrl,
      channel: 15,
    }
    appShare(appShareParams)
  }
}
//微信分享
export function regShare(share = {}, friendShare = {}, componentKey) {
  let shareObj = Object.assign({
    title: '', // 分享标题
    desc: '', // 分享描述
    link: '', // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
    imgUrl: '', // 分享图标
    success: function () {
      // 设置成功
    }
  }, share);
  let shareFriendObj = Object.assign({
    title: '', // 分享标题
    link: '', // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
    imgUrl: '', // 分享图标
    success: function () {
      // 设置成功
      // alert('分享朋友圈设置成功')
    },
    trigger: function() {
      // alert('分享朋友圈trigger')
    }
  }, friendShare);
  wx.updateAppMessageShareData(shareObj)
  wx.updateTimelineShareData(shareFriendObj)
}
//app,极速app分享
export function appShare(params) {
  // eslint-disable-next-line
  JSBridge.doAction(
    'actionShare',
    JSON.stringify({
      succeedCallback: '',
      failedCallback: '',
    }),
    JSON.stringify({
      title: params.title,
      content: params.content,
      url: params.url,
      imgUrl: params.imgUrl,
      channel: params.channel,
    })
  )
}
//小程序分享
export function miniAppShare(data) {
  wx.miniProgram.postMessage({
    data: {
      title: data.title,
      desc: data.desc,
      path: data.path,
      imgUrl: data.imgUrl,
      isOpenUrl: data.isOpenUrl,
      componentKey: data.componentKey
    }
  })
}
//设置分享时候拼的域名及ip
export function shareIp() {
  const envType = process.env.ENV_TYPE
  console.log(envType)
  if (envType === "localhost") {
    return window.location.origin
  } else if (envType === "development" || envType === "development2") {
    return "https://h5-test.commeal.cn"
  } else if (envType === "production" || envType === "production2") {
    const { origin, pathname } = window.location
    if(isFeiBian) {
      const _url = origin + pathname
      return _url.substring(0, _url.lastIndexOf('/'))
    }
    return "https://ym-h-bd.dayustudy.com"
  } else if (envType === "pre") {
    return "https://h-bd-test.commeal.cn/magic_cube"
  }
}
