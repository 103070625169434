import request from '@/utils/request';
//获取课程详情
export const getGoodsDetail = async (params) => {
  return request('order_api/goods/queryDetail', {
    method: 'GET',
    params,
    headers:{
      appId: localStorage.getItem('appId'),
      brandId: localStorage.getItem("brandId") || 18
    }
  })
}
//下单支付 /order_api/unifiedOrder/jsapi/unifiedOrder POST
export const createOrder = async (data) => {
  return request('order_api/unifiedOrder/jsapi/createOrder', {
    method: 'POST',
    data,
    headers:{
      appId: localStorage.getItem('appId'),
      brandId: localStorage.getItem("brandId") || 18
    }
  })
}
//发送验证码
export const senCount = async (data) =>{
  return request('fl/bfUser/login/sendVverifyCode', {
    method: 'POST',
    data,
    headers:{
      appId: localStorage.getItem('appId'),
    }
  })
}
//绑定手机号 /fl/bfUser/wx/h5/bindPhone
export const bindPhone = async(data) =>{
  return request('fl/bfUser/wx/h5/bindPhone', {
    method: 'POST',
    data,
    headers:{
      appId: localStorage.getItem('appId'),
    }
  })
}
//查询订单详情/order_api/unifiedOrder/orderDetail
export const orderDetail = async(params) =>{
  return request('order_api/unifiedOrder/orderDetail', {
    method: 'GET',
    params,
    headers:{
      appId: localStorage.getItem('appId'),
      brandId: localStorage.getItem("brandId") || 18
    }
  })
}
