

  import { message } from 'antd';

  export function takeScreenshot(domId){
    var shareContent = document.getElementById(domId);//需要截图的包裹的（原生的）DOM 对象
    var width = shareContent.clientWidth; //获取dom 宽度
    var height = shareContent.clientHeight; //获取dom 高度
    var canvas = document.createElement("canvas"); //创建一个canvas节点
    var scale = 6; //定义任意放大倍数 支持小数
    canvas.width = width * scale; //定义canvas 宽度 * 缩放
    canvas.height = height * scale; //定义canvas高度 *缩放
    canvas.getContext("2d").scale(1,1); //获取context,设置scale

    var rect = shareContent.getBoundingClientRect();//获取元素相对于视察的偏移量
    canvas.getContext("2d").translate(-rect.left,-rect.top);//设置context位置，值为相对于视窗的偏移量负值，让图片复位
    var opts = {
      scale:scale, // 添加的scale 参数
      canvas:canvas, //自定义 canvas
      logging: false, //日志开关
      width:width, //dom 原始宽度
      height:height, //dom 原始高度
      x:rect.left/1.2,
      y:rect.top/1.2,
      // windowWidth:document.body.scrollWidth,
      // windowHeight:document.body.scrollHeight,
      // x:0,
      // y:window.pageYOffset,
      useCORS:true,
      backgroundColor: 'transparent',
    };
    return {shareContent, opts}
  }

  /**
   * 根据window.devicePixelRatio获取像素比
   */
  function DPR() {
    if (window.devicePixelRatio && window.devicePixelRatio > 1) {
        return window.devicePixelRatio;
    }
    return 1;
  }

  export function dataURLtoBlob(dataurl) {
    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n)
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n)
    }
    return new Blob([u8arr], { type: mime })
  }

  export function downloadBase64(dataUrl, filename) {
    var imageFile, href
    const downloadLink = document.createElement('a')
    try {
      downloadLink.style.display = 'none'
      var blob = dataURLtoBlob(dataUrl)
      var href = window.URL.createObjectURL(blob)
      downloadLink.download = filename
      downloadLink.href = href
      downloadLink.click()
    } catch (err) {
      message.error('请重试')
    } finally {
      if (href) {
        window.URL.revokeObjectURL(href)
      }
      // downloadLink.remove()
    }
  }

  export function convertImgToBase64(url, callback, outputFormat){
    let canvas = document.createElement('canvas'),
      ctx = canvas.getContext('2d'),
      img = new Image();
    img.crossOrigin = '*';//Anonymous
    img.onload = function(){
        canvas.height = img.height;
        canvas.width = img.width;
        ctx.drawImage(img,100,100);
        var dataURL = canvas.toDataURL(outputFormat || 'image/png');
        console.log('---------',dataURL)
        callback.call(this, dataURL);
        canvas = null;
    };
    img.src = url;
  }
  export function getBase64Image (url, callBack) {
    var image = new Image()
    image.crossOrigin = '*' // 支持跨域图片
    image.src = url + '?v=' + Math.random() // 处理缓存
    image.onload = function () {
        var base64 = drawBase64Image(image)
        if(callBack){
            callBack.call(this, base64);
        }
    }
}
function drawBase64Image (img) {
    var canvas = document.createElement('canvas')
    canvas.width = img.width
    canvas.height = img.height
    var ctx = canvas.getContext('2d')
    ctx.drawImage(img, 0, 0, img.width, img.height)
    var dataURL = canvas.toDataURL('image/png')
    return dataURL
}
