/*
 * @Author: 顾喜斌 xibin_gu@163.com
 * @Date: 2022-08-26 20:07:37
 * @LastEditors: dulei
 * @LastEditTime: 2022-09-29 20:10:09
 * @FilePath: /sscp/src/pages/huayang/components/ToAppHeader/index.js
 * @Description: 打开app公共组件
 * @params appKey
 */
/* eslint-disable jsx-a11y/anchor-has-content */
import React, {useState, useRef, forwardRef,useImperativeHandle} from 'react';
import WxOpenLaunchApp from "../../../xzIndex/WxOpenLaunchApp";
import { toDownloadApp, isWexin } from "../../../xzIndex/help";
import styles from './style.less'
import {getAppInfoByAppKey} from '@/utils/utils';

let ToAppHeader = (props,ref) => {
  const [appInfo, setAppInfo] = useState(getAppInfoByAppKey(props.appKey))
  const [fixedPosition, setFixedPosition] = useState('top')
  // 此处注意useImperativeHandle方法的的第一个参数是目标元素的ref引用
  useImperativeHandle(ref, () => ({
    // 暴露给父组件的方法
    toDownloadApp,
    setFixedPosition
  }))
  // 打开App
  function openApp(){
      // toDownloadApp('hyxz')
    // props.setAddDot && props.setAddDot(props.actionKey || '')
  }
  return <>
    {/* 打开App */}
    <div className={`${styles.topOpenApp} ${fixedPosition==='top'?styles.topFixed:styles.bottomFixed}`} onClick={()=>openApp()}>
     {fixedPosition==='top' && <WxOpenLaunchApp enterType={props.enterType} setAddDot={props.setAddDot} actionKey={'tz1'} hyyyAppId={'wx0cb86bba04410bd5'} extinfo={JSON.stringify({type: 'index', source:'normal'})} style={{
        position: 'absolute',
        top: '0px', left: 0,
        width: '100%', height: '70px',
        opacity: 0, background: 'black', zIndex: 999 }}>
        打开APP
        <div style={{
        width: '100%', height: '70px',
        opacity: 0, background: 'yellow' }} />
      </WxOpenLaunchApp>}
      {fixedPosition!=='top' && <WxOpenLaunchApp enterType={props.enterType} setAddDot={props.setAddDot} actionKey={'tz2'} hyyyAppId={'wx0cb86bba04410bd5'} extinfo={JSON.stringify({type: 'index', source:'normal'})} style={{
        position: 'absolute',
        top: '0px', left: 0,
        width: '100%', height: '70px',
        opacity: 0, background: 'black', zIndex: 999 }}>
        打开APP
        <div style={{
        width: '100%', height: '70px',
        opacity: 0, background: 'yellow' }} />
      </WxOpenLaunchApp>}
      <img className={styles.appImage} src={appInfo?.logo} alt="" />
      <div className={styles.appInfo}>
        <p className={styles.appName}>{appInfo?.appDes}</p>
      </div>
      <div className={styles.appBtn}>打开APP</div>
    </div>
  </>
}
ToAppHeader = forwardRef(ToAppHeader)

export default ToAppHeader
